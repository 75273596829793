 import '../Componentes/nav.css';
import { Link } from "react-router-dom";

const styles = {

    nav: {

        height: "30px",
        color: "#008b8b",
        backgroundColor: "black",
        padding: "0",

    },


    ul: {
        listStyle: "none",
        textDecoration: "none",
        display: "flex",
        paddingTop: "5px",
        paddingLeft: "40px",
    },

    li: {


        flexGrow: "1",
    },

    a: {
        color: "#008b8b",
        textDecoration: "none",
        fontStyle: "italic",
        fontWeight: "bold",
    }



}

const Nav = (props) => {



    return (

        //    <nav style={styles.nav}>


        //         <div>

        //          <ul style={styles.ul} >

        //                 <li style={styles.li}><Link style={styles.a} to="/">Home</Link></li>
        //                 <li style={styles.li}><Link style={styles.a} to="/Cuerdas">Cuerdas</Link></li>
        //                 <li style={styles.li}><Link style={styles.a} to="/Percusion">Percusion</Link></li>
        //                 <li style={styles.li}><Link style={styles.a} to="/Teclados">Teclados</Link></li>
        //                 <li style={styles.li}><Link style={styles.a} to="/Vientos">Vientos</Link></li>
        //                 <li style={styles.li}><Link style={styles.a} to="/Filtros">E-Comerce</Link></li>
        //                 <li style={styles.li}><Link style={styles.a} to="/Nosotros">Nosotros</Link></li>
        //                 <li style={styles.li}><Link style={styles.a} to="/Contacto">Contacto</Link></li>



        //             </ul>



        //         </div>




        //     </nav>


        <nav >


            <center><div>

                <ul >

                    <li ><Link to="/Listaeditar">Movil</Link></li>
                    <li ><Link to="/Listarmoviles">Moviles</Link></li>
                    <li ><Link to="/abmrutas">Rutas</Link></li>
                    <li ><Link to="/importar">Importar Reclamos</Link></li>
                    
                    
                    
                  



                </ul>



            </div></center>




        </nav>

    )
}

export default Nav;