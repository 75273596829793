
import { useForm } from "react-hook-form"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Row, Col, } from 'react-bootstrap'
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import Select from "react-select";
import useFetch from "../Componentes/useFetch";
import { Link } from "react-router-dom";
import '../Estilos/ordenes.css';
import Mensaje from "../Componentes/Mensaje";

const Insertaruta = (props) => {

  const styles = {

    img: { height: "60px", width: "230px", paddingLeft: "120px" },
    header: {
      backgroundColor: "rgb(0, 0, 0)",
      width: "350px",

      color: "#008b8b",

    },
  }



  const { register, setValue, handleSubmit, formState: { errors } } = useForm();

 
  const [isopen, setIsopen] = useState(false);
  const [msg, setMsg] = useState('');
  const [guardado, setGuardado] = useState(false)
  const [usuarios, setUsuarios] = useState([])
  
  //  const { data, loading } = useFetch("http://localhost/BVC/index.php/Resolucion",)



  const handleselectchange = (event) => {
    console.log(event.value)

    setValue('usuario', event.value)

}


 

  let navigate = useNavigate();
  let goBack = () => {
    navigate(-1);
  };



  // const onSubmit = (data) => console.log(data)

  const onSubmit = data => {

    setMsg('');




    fetch("https://dcocce.host/BVC/index.php/Rutas/Insertaruta_json", {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(resp => {



        setGuardado(resp.guardado);
        setIsopen(resp.guardado);
        setMsg(resp.mensaje);

      })

  };

  useEffect(
    () => {


      //fetch("https://dariococce.000webhostapp.com/Instrumentos/",)
      fetch("https://dcocce.host/BVC/index.php/Usuarios/allusuarios/",)

        .then(res => res.json())
        .then(data => {

          setUsuarios(data);
          console.log(usuarios)


        })




    },

    []

  )

  return (

    <div className="d-flex flex-column" >

      <div><p>  </p></div>

      <div className="d-flex justify-content-center">


        <Card style={styles.header} className="text-center">
          <Card.Img variant="top" style={styles.img} src="img/bvcplay.png" />
          <Card.Body>
            <Card.Title className="text-center">Agregar Nueva Ruta</Card.Title>
            <Card.Text >



              <form onSubmit={handleSubmit(onSubmit)}>



                <table>

                  <tr>
                    <td>

                      <label>Nº de Ruta</label>
                    </td>
                    <td>

                      <input type="text"
                        {...register("numero", { required: true })} />
                      {errors.numero && <p> numero de Ruta obligatorio</p>}

                    </td>
                  </tr>

                  <tr>
                    <td>

                      <label>Usuario</label>
                    </td>
                    <td>



                      <p>    <Select

                        options={usuarios.map(usuario => ({ value: usuario.usuario, label: usuario.usuario }))}
                        onChange={handleselectchange}


                      /> </p>


                    </td>
                  </tr>

                  <tr>
                    <td>

                      <label>Tecnico</label>
                    </td>
                    <td>

                      <input type="text"
                        {...register("tecnico", { required: true })} />
                      {errors.tecnico && <p> numero de tecnico obligatorio</p>}

                    </td>
                  </tr>

                  <tr>
                    <td>

                      <label>Fecha</label>
                    </td>
                    <td>

                      <input type="date"
                        {...register("fecha", { required: true })} />
                      {errors.fecha && <p> la fecha es obligatoria</p>}


                    </td>
                  </tr>




                </table>

                <br />



                <br />



                <div className="d-grid gap-2">

                  <Button variant="primary" onClick={goBack} >VOLVER</Button>
                </div>
                <br />
                <div className="d-grid gap-2">

                  <Button variant="primary" type="submit">Insertar</Button>

                </div>





                {msg ? <p>{msg}</p> : null}

                <Mensaje isopen={isopen} msg={msg} aceptar={goBack} /> 

                <div>

                </div>
              </form>

            </Card.Text>

          </Card.Body>
        </Card>

      </div>

    </div>



  );
}

export default Insertaruta;
