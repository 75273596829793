import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from "@fortawesome/free-solid-svg-icons";

const Header2 = (  {nombre,fecha} ) => {
    
   
 


    const styles = {

        img: { height: "80px", paddingLeft: "45px" },

        header: {
            backgroundColor: "rgb(0, 0, 0)",

            color: "#008b8b",
            display: "flex",
            flexDirection: "row",
           
            justifyContent: "space-around",

        },

        headerh2: {

            fontStyle: "italic",
            fontWeight: "bold",
        }



    }

    return (
        
        <header style={styles.header}>

            <div>
                <img style={styles.img}  src="img/bvcplay.png" alt="" />
            </div>

            <div >
                <h2 style={styles.headerh2}>Departamento Tecnico</h2>
                <p>Ruta de Reclamos BVC</p>
            </div>
<div>
    
 { nombre == 'vacio' ? <p>Login</p>: <p>Logout  : {nombre} fecha: {fecha} </p> }   


</div>
           

        </header>

    )
}

export default Header2;