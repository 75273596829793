



import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useFetch from "../Componentes/useFetch"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { format, parseISO } from 'date-fns';

function Hojaderutastate({ user, fecha }) {



    console.log(user, fecha);

    const { data, loading, ruta } = useFetch("https://dcocce.host/BVC/index.php/Rutas/rutajson/" + user + "/" + fecha,)
    const [elementos, Setelementos] = useState([]);
    const [cumplido, setCumplido] = useState(true)
    const [mostrar, setMostrar] = useState(0);
    const [muestra, setMuestra] = useState(0);
    const [formattedDate, setFormattedDate] = useState('');

    const parsedDate = parseISO(fecha);
    const formatted = format(parsedDate, 'dd/MM/yyyy');
     


    let i = (true)




    if (loading) {
        return (
            <h3>loading .. .. ..</h3>
        )
    } else {



        return (




            <div >



                <center><h4>Rutas de reclamos:  Usuario: {user} - Fecha: {formatted}</h4></center>
                <div className='inicio'>



                    <Container>

                        <Row>
                            <Col className="col-3 col-sm-2" >Inter</Col>
                            <Col  className="col-3 col-sm-2" >TV</Col>
                            <Col className="col-3 col-sm-2" >Direccion</Col>
                            <Col className="col-sm-2 d-none d-md-block">Apellido</Col>
                            <Col className="col-sm-1 d-none d-lg-block">Zona</Col>
                            <Col className="col-sm-2 d-none d-lg-block">Falla</Col>
                            <Col className="col-2 col-sm-1" style={{ width: '2%' }}></Col>


                        </Row>

                    </Container>

















                    {data.map(elemento =>


                        <Container className="border border-1">
                            {elemento.cumplido == 1 ?
                                <Row>
                                    <Col className="col-3 col-sm-2" >{elemento.ni}</Col>
                                    <Col className=" col-3 col-sm-2" >{elemento.nc}</Col>
                                    <Col className="col-3 col-sm-2" >{elemento.direccion}</Col>
                                    <Col className="col-sm-2 d-none d-md-block" >{elemento.apellido}</Col>
                                    <Col className="col-sm-1 d-none d-lg-block">{elemento.zona}</Col>
                                    <Col className="col-sm-2 d-none d-lg-block">{elemento.observaciones}</Col>

                                    {elemento.cumplido == 0 ? <Col className="col-3 col-sm-1" style={{ width: '2%' }}><Link to="/Cerrarods" state={elemento}  > <FontAwesomeIcon icon={faPenToSquare} /></Link></Col> :
                                        <Col className="col-3 col-sm-1" style={{ width: '2%' }}><Link to="/Verods" state={elemento}  > <FontAwesomeIcon icon={faFloppyDisk} /></Link> </Col>}


                                    {/* <Col className="col-sm-2"><Link to="/Verods" state={elemento}  > <FontAwesomeIcon icon={faPenToSquare} /></Link> </Col> */}

                                    {/* {elemento.numrec < 3 ? (elemento.cumplido == 1 ?
                                    <Col sm><Link to={"/Hoja1"}> <FontAwesomeIcon icon={faTrash} /></Link> </Col> : <Col sm> <FontAwesomeIcon icon={faTrash} /> </Col>)
                                 : null } */}

                                </Row>
                                : null}

                            {elemento.cumplido == 0 && i ?
                                <Row>
                                    <Col className="col-3 col-sm-2" >{elemento.ni}</Col>
                                    <Col className="col-3 col-sm-2" >{elemento.nc}</Col>
                                    <Col className=" col-3 col-sm-2" >{elemento.direccion}</Col>
                                    <Col className="col-sm-2 d-none d-md-block" >{elemento.apellido}</Col>
                                    <Col className="col-sm-1 d-none d-lg-block">{elemento.zona}</Col>
                                    <Col className="col-sm-2 d-none d-lg-block">{elemento.observaciones}</Col>

                                    {i = (false)}

                                    {elemento.cumplido == 0 ? <Col className="col-3 col-sm-1" style={{ width: '2%' }}><Link to="/Cerrarods" state={elemento}  > <FontAwesomeIcon icon={faPenToSquare} /></Link></Col> :
                                        <Col className="col-3 col-sm-1" style={{ width: '2%' }}> <Link to="/Verods" state={elemento}  > <FontAwesomeIcon icon={faFloppyDisk} /></Link></Col>}

                                  

                                </Row>
                                : null}




                        </Container>




                    )}




                    {ruta ? null : <p> NO HAY RUTAS CON ESA FECHA Y/O USUARIO </p>}

                </div>

            </div>



        )

    }



}

export default Hojaderutastate;
