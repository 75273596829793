const Footer = (props) => {
const styles={

    footer: {
        backgroundColor: "#008b8b",
        textAlign: "right",
        paddingRight: "10px",
    }
}

    return (
        <footer style={styles.footer}>
            Diseñado por estudio CDC
        </footer>

    )
}

export default Footer;