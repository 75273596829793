import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket, faUser } from "@fortawesome/free-solid-svg-icons";

const Logout = (  {setNombre} ) => {
    
   
    
const handleClick = () => {

 setNombre('vacio')

}


    const styles = {

        img: { height: "470px", paddingLeft: "45px" },

        header: {
            backgroundColor: "rgb(0, 0, 0)",

            color: "#008b8b",
            display: "flex",
            flexDirection: "row",
           
            justifyContent: "space-around",

        },

        headerh2: {

            fontStyle: "italic",
            fontWeight: "bold",
        }



    }

    return (
        
        <header style={styles.header}>

            <div>
               
            </div>

            <div >
              
            </div>
<div>

    
<Link to="/" ><FontAwesomeIcon icon={faRightToBracket} onClick={handleClick} /></Link>


    
<FontAwesomeIcon icon={faUser} />

</div>
           

        </header>

    )
}

export default Logout;