
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

const ImportExcel = () => {

  const [data, setData] = useState([]);
  const [msg, setMsg] = useState('');

  const datos = [
    { NI: 1247, Ruta: 1,Apellido: "Moriones", Domicilio: "Rondeau 567" },
    { NI: 745, Ruta: 1,Apellido: "Juan", Domicilio: "Moreno 524" },
    { NI: 4458, Ruta: 1,Apellido: "Riquelme", Domicilio: "Santo Domingo 12874" }
  ];
  
  const handleInsert =  () => {

    setMsg('');



   

console.log(data)
console.log(datos)

    fetch("https://dcocce.host/BVC/index.php/Rutas/Importar_json", {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(resp => {



        setMsg(resp.mensaje);

      })

  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // Suponiendo que solo hay una hoja en el archivo Excel
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convierte los datos de la hoja a un formato JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setData(jsonData);
      console.log(data)
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <h1>Importar Archivo Excel</h1>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      {/* <div>
        <h2>Datos Importados:</h2>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div> */}
   

    <div className='inicio'>



      <Container>

        <Row>
          <Col className=" d-none d-lg-block">Numero Cable</Col>

          <Col className=" d-none d-lg-block">Numero Internet</Col>
          <Col className=" d-none d-lg-block">Apellido</Col>
          <Col className="d-none d-lg-block ">Direccion</Col>
          <Col className="d-none d-lg-block ">Empresa</Col>



        </Row>

      </Container>


{console.log(data)}


      {data.map(data =>


        <Container className="border border-1">

          <Row>
            <Col className="d-none d-lg-block" >{data.NC}</Col>
            <Col className="d-none d-lg-block" >{data.NI}</Col>
            <Col className="d-none d-lg-block">{data.Apellido}</Col>
            <Col className="d-none d-lg-block">{data.Domicilio}</Col>
            <Col className="d-none d-lg-block">{data.Empresa}</Col>


          </Row>







        </Container>




      )}

      <Container className="border border-1">

        <Row>
<br/>
<br/>
        </Row>
      

        <Row>

          <Button variant="dark" onClick={handleInsert}>Importar </Button>

        </Row>



        {msg ? <p>{msg}</p> : null}



      </Container>



    </div>

    </div>


  );
};

export default ImportExcel;

