
import { useForm } from "react-hook-form"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Row, Col, } from 'react-bootstrap'
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import Select from "react-select";
import useFetch from "../Componentes/useFetch";
import { Link } from "react-router-dom";
import '..//Estilos/header.css';
import '../Estilos/ordenes.css';
import Mensaje from "../Componentes/Mensaje";

const Modificarods = (props) => {

  const styles = {

    img: { height: "60px", width: "230px", paddingLeft: "120px" },
    header: {
      backgroundColor: "rgb(0, 0, 0)",
      width: "350px",

      color: "#008b8b",
     
  },
  }



  const { register, setValue, handleSubmit, formState: { errors } } = useForm();

  const location = useLocation();

  const elemento = location.state;

  const [isopen, setIsopen] = useState(false);
  const [msg, setMsg] = useState('');
  const [datos, setDatos] = useState([]);
  const [suma, setSuma] = useState({});
  const [guardado, setGuardado] = useState(false)
  const [resoluciones, setResolucion] = useState([])
  //  const { data, loading } = useFetch("http://localhost/BVC/index.php/Resolucion",)


  const Cerrar = () => {

    setIsopen(false)

  


}

  const handleselectchange = (event) => {
    console.log(event.value)

    setValue('cod_resol', event.value)

  }

  const newelem = {
    id: elemento.id,
   
  }

  let navigate = useNavigate();
  let goBack = () => {
    navigate(-1);
  };



  // const onSubmit = (data) => console.log(data)

  const onSubmit = data => {

    setMsg('');



    setSuma({

      ...data,
      ...newelem
    })



    fetch("https://dcocce.host/BVC/index.php/Rutas/Modificar_json", {
      method: "POST",
      body: JSON.stringify(suma)
    })
      .then(res => res.json())
      .then(resp => {



        setGuardado(resp.guardado);
        setIsopen(true);
        setMsg(resp.mensaje);

      })

  };

  useEffect(
    () => {


      //fetch("https://dariococce.000webhostapp.com/Instrumentos/",)
      fetch("https://dcocce.host/BVC/index.php/Resolucion",)

        .then(res => res.json())
        .then(data => {

          setResolucion(data);
          console.log(resoluciones)


        })




    },

    []

  )

  return (

    <div className="d-flex flex-column" >

    <div><p>  </p></div>

<div className="d-flex justify-content-center">

      <Card style={styles.header} className="text-center">
      <Card.Img variant="top" style={styles.img} src="img/bvcplay.png" />
        <Card.Body>
          <Card.Title className="text-center">Modificar Orden</Card.Title>
          <Card.Text >



            <form onSubmit={handleSubmit(onSubmit)}>



              <table>

                <tr>
                  <td>

                    <label> Nº Internet</label>
                  </td>
                  <td>

                    <input type="text" 
                       {...register("ni", {value: elemento.ni }, { required: true })} />
                      {errors.ni && <p> el numero de internet es obligatoria</p>}


                  </td>
                </tr>

                
                <tr>
                  <td>

                    <label> Nº Cable</label>
                  </td>
                  <td>

                    <input type="text" 
                       {...register("nc", {value: elemento.nc }, { required: true })} />
                      {errors.nc && <p> el numero de cable es obligatoria</p>}


                  </td>
                </tr>
                <tr>
                  <td>

                    <label>Apellido</label>
                  </td>
                  <td>

                    <input type="text" 
                      {...register("apellido", {value: elemento.apellido }, { required: true } )}   />
                      {errors.apellido && <p> El apellido es obligatoria</p>}



                  </td>
                </tr>
               
                <tr>
                  <td>

                    <label>Direccion</label>
                  </td>
                  <td>

                    <input type="text" 
                      {...register("direccion",{value: elemento.direccion }, { required: true })} />
                      {errors.direccion && <p> La Direccion es obligatoria</p>}


                  </td>
                </tr>
              
                <tr>
                  <td>

                    <label>Falla</label>
                  </td>
                  <td>

                  <textarea cols={20} rows={3}
                       {...register("observaciones", {value: elemento.observaciones}, { required: true })} />
                      {errors.observaciones && <p> La falla es obligatoria</p>}

                      
                       

                  </td>
                </tr>

                </table>


                <br />



                <br />

               
                <div className="d-grid gap-2">
                  <Button variant="primary" onClick={goBack} >VOLVER</Button>
                  </div> 
                  <br />
                  <div className="d-grid gap-2"> 
                    <Button variant="primary" type="submit">Modificar</Button>

                    </div>
                    

                  {msg ? <p>{msg}</p> : null}
{console.log(msg,isopen)}
                  <Mensaje isopen={isopen} msg={msg} aceptar={goBack} />
               
             

              <div>

              </div>
            </form>

          </Card.Text>

        </Card.Body>
      </Card>

      </div>

    </div>



  );
}

export default Modificarods;
