

import { redirect, useLocation, useParams } from "react-router-dom";
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from "react-select";
import { useForm } from "react-hook-form"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faTrash, faPenToSquare, faFileImport, faUpLong } from "@fortawesome/free-solid-svg-icons";
import Confirmacion from "../Componentes/Confirmacion";
import { format, parseISO } from 'date-fns';

import Nav from "../Componentes/Nav"

function Abmrutas({ user, fecha, setNombre, setFecha }) {


let Navigate = useNavigate()



    // const { data, loading } = useFetch("http://localhost/BVC/index.php/Rutas/rutajson/" + user + "/" + fecha,)
    const [elementos, Setelementos] = useState([]);
    const [cumplido, setCumplido] = useState(true)
    const [mostrar, setMostrar] = useState(0);
    const [nfecha, setNfecha] = useState(fecha);
    

    const [ver, setVer] = useState(false);
    const [isopen, setIsopen] = useState(false);
    const [newelem, setNewelem] = useState({});
    const [data, setData] = useState(null)
    const [usuario, setUsuario] = useState(user)
    const [usuarios, setUsuarios] = useState([])
    const [loading, Setloading] = useState(true)
    const [ruta, SetRuta] = useState(false)
    const [elem, setElem] = useState({});
    const [chk, setChecked] = useState(false)
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const [formattedDate, setFormattedDate] = useState('');
   
    let w = (false)



    useEffect(() => {

        console.log("elem modificado:", elem)


        fetch("https://dcocce.host/BVC/index.php/Rutas/Modificar", {
            method: "POST",
            body: JSON.stringify(elem)
        })
            .then(res => res.json())
            .then(resp => {

            })

        fetch("https://dcocce.host/BVC/index.php/Usuarios/allusuarios/",)

            .then(res => res.json())
            .then(data => {

                setUsuarios(data);
                console.log(usuarios)
            })

            const parsedDate = parseISO(nfecha);
        const formatted = format(parsedDate, 'dd/MM/yyyy');
            setFormattedDate(formatted);

        Setloading(true)
        fetch("https://dcocce.host/BVC/index.php/Rutas/rutasjson/"  + nfecha,)

            .then(res => res.json())
            .then(datas => {

                Setloading(true)
                // setData(data)



                setData(datas)

                if (datas.length > 0) {

                    SetRuta(true)
                } else {

                    SetRuta(false)

                }


                Setloading(false)
                console.log(datas)
                console.log(datas.length)


            })



    },

        [ver]



    );


    const handleonCheckbox = async (event, { elemento }) => {
        console.log(event.target.checked)

        setElem(elemento)
        console.log(elemento.auto)

        if (event.target.checked) {

            setElem(prevData => ({ ...prevData, auto: "1" }))

            console.log(elem)
        } else {

            setElem(prevData => ({ ...prevData, auto: "0" }))

            console.log(elem)
        }




        setVer(!ver);


    }



    const handleselectchange = (event) => {
        console.log(event.value)

        setValue('usuario', event.value)

    }

    const handleClick = ({ elemento }) => {

        setIsopen(true);
        setVer(!ver);

        setNewelem(elemento)


    }

    const handleClickruta = () => {

        return Navigate("/insertaruta")

    }


    const handleUser = data => {

        setIsopen(false)

        setUsuario(data.usuario)
        setNfecha(data.fecha)
        setNombre(data.usuario)
        setFecha(data.fecha)
        console.log(usuario)
        console.log(nfecha)
        setVer(!ver)

    }


    const Cerrar = () => {

        setIsopen(false)

        setVer(!ver);


    }



    const handleBaja = async ({ newelem }) => {


console.log(newelem)



        fetch("https://dcocce.host/BVC/index.php/Rutas/Borrarruta_json", {
            method: "POST",
            body: JSON.stringify(newelem)
        })
            .then(res => res.json())
            .then(resp => {





                setIsopen(false)
                setVer(!ver);




            })



    };




    if (loading) {
        return (

            <h3>loading .. .. ..</h3>
        )
    } else {



        return (




            <div >

                <Nav />


                <Navbar className="bg-body-tertiary justify-content-end">

                    <form onSubmit={handleSubmit(handleUser)}>



                        <Row>

                            <Col >
                                <br />
                                <div className="d-grid gap-2">
                                <Button variant="primary"  onClick={handleClickruta} >Nueva.Ruta</Button>
                                </div>

                            </Col>

                            <Col >

                                <label>Fecha</label>

                                <p>   <input type="date"
                                    {...register("fecha", { required: true })} />
                                    {errors.fecha && <p> La fecha es obligatoria</p>}

                                </p>

                            </Col>

                            {/* <Col >

                                <label>Usuario</label>
                                <p>    <Select

                                    options={usuarios.map(usuario => ({ value: usuario.usuario, label: usuario.usuario }))}
                                    onChange={handleselectchange}


                                /> </p>

                            </Col> */}
                            <Col >
                                <br />

                                <Button type="submit">Buscar ruta</Button>

                            </Col>



                        </Row>
                    </form>



                </Navbar>




                <center><h3>Rutas de reclamos  -  Fecha: {formattedDate} </h3></center>

                <div className='inicio'>



                    <Container fluid>

                        <Row>
                            <Col className="col-1 col-sm-1 ">Usuario</Col>
                            <Col className="col-1 col-sm-1 ">Tecnico</Col>
                            <Col className="col-2 col-sm-2 ">Numero</Col>
                            <Col className="col-2 col-sm-2 d-none d-lg-block">Fecha</Col>
                            



                        </Row>

                    </Container>











                    {data.map(elemento =>




                        <Container fluid className="border border-1">


                            <Row>


                                <Col className="col-1 col-sm-1 " >{elemento.usuario}</Col>
                                <Col className="col-1 col-sm-1 " >{elemento.tecnico}</Col>
                                <Col className="col-2 col-sm-2 " >{elemento.numero}</Col>
                                <Col className="col-sm-2 d-none d-lg-block">{elemento.fecha}</Col>
                               









                                <Col className="col-1 col-sm-1" style={{ width: '2%' }}><Link to="/Modificarruta" state={elemento}  > <FontAwesomeIcon icon={faPenToSquare} /></Link> </Col>

                                <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <FontAwesomeIcon icon={faTrash} onClick={() => handleClick({ elemento })} /> </Col>

                                <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <input
                                    type="checkbox"
                                    name="auto"
                                    onChange={(event) => handleonCheckbox(event, { elemento })}
                                    checked={w}

                                />

                                </Col>
                            </Row>












                        </Container>

                    )}







                    {ruta ? null : <p> NO HAY RUTAS CON ESA FECHA Y/O USUARIO </p>}

                    <Confirmacion isopen={isopen} close={Cerrar} borrar={() => handleBaja({ newelem })} />

                    {/* {ver ? <p> Muestro el CLICK </p> : null} */}

                    {/* 
                    <Modal isopen={isopen} close={Cerrar} borrar={() => handleBaja({ newelem })} /> */}



                </div>

            </div>



        )

    }



}

export default Abmrutas;
