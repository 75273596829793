

import { redirect, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from "react-select";
import { useForm } from "react-hook-form"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faTrash, faPenToSquare, faFileImport, faUpLong ,faFile } from "@fortawesome/free-solid-svg-icons";
import Confirmacion from "../Componentes/Confirmacion";
import { format, parseISO } from 'date-fns';

import Nav from "../Componentes/Nav"

function Listaeditar({ user, fecha, setNombre, setFecha }) {


    const styles = {

        img: { height: "20px", width: "30px", paddingLeft: "7px" },
    
       
    
      }



    // const { data, loading } = useFetch("http://localhost/BVC/index.php/Rutas/rutajson/" + user + "/" + fecha,)
    const [elementos, Setelementos] = useState([]);
    const [cumplido, setCumplido] = useState(true)
    const [mostrar, setMostrar] = useState(0);
    const [nfecha, setNfecha] = useState(fecha);
    
    const [ver, setVer] = useState(false);
    const [isopen, setIsopen] = useState(false);
    const [newelem, setNewelem] = useState({});
    const [data, setData] = useState([])
    const [usuario, setUsuario] = useState(user)
    const [usuarios, setUsuarios] = useState([])
    const [loading, Setloading] = useState(true)
    const [ruta, SetRuta] = useState(false)

    const { register, setValue, handleSubmit, formState: { errors } } = useForm();

    const [formattedDate, setFormattedDate] = useState('');



    useEffect(() => {

        const parsedDate = parseISO(nfecha);
        const formatted = format(parsedDate, 'dd/MM/yyyy');
            setFormattedDate(formatted);

        Setloading(true)
        fetch("https://dcocce.host/BVC/index.php/Rutas/ContarMovil_json/" + nfecha,)

            .then(res => res.json())
            .then(datas => {

                Setloading(true)
                // setData(data)



                setData(datas)

                if (datas.length > 0) {

                    SetRuta(true)
                } else {

                    SetRuta(false)

                }


                Setloading(false)
                console.log(datas)
                console.log(datas.length)


            })



    },

        [ver]



    );




    const handleUser = data => {

       

        
        setNfecha(data.fecha)
       
        setFecha(data.fecha)
        
        console.log(nfecha)
        setVer(!ver)

    }
    

   


    

   

    if (loading) {
        return (

            <h3>loading .. .. ..</h3>
        )
    } else {



        return (




            <div >

                <Nav />


                <Navbar className="bg-body-tertiary justify-content-end">

                    <form onSubmit={handleSubmit(handleUser)}>



                        <Row>

                            <Col >

                                <label>Fecha</label>

                                <p>   <input type="date"
                                    {...register("fecha", {value: fecha }, { required: true })} />
                                    {errors.fecha && <p> La fecha es obligatoria</p>}

                                </p>

                            </Col>

                           
                            <Col >
                           <br />
                            
                                <Button type="submit">Buscar rutas</Button>
                               
                            </Col>
                        </Row>
                    </form>



                </Navbar>




                <center><h3>Estado de Moviles -  Fecha: {formattedDate} </h3></center>
                <div className='inicio'>



                    <Container fluid>

                        <Row>
                            <Col className="col-1 col-sm-1 ">Usuario</Col>
                            <Col className="col-1 col-sm-1 ">Reclamos</Col>
                            <Col className="col-2 col-sm-2 ">Cumplidos</Col>
                            <Col className="col-2 col-sm-2 d-none d-lg-block">Pendientes</Col>
                            <Col className="col-1 col-sm-1 d-none d-lg-block">Estado</Col>
                            
                          
                                    


                        </Row>

                    </Container>







                    {console.log(data)}



                    {data.map(elemento =>

                        <Container fluid className="border border-1">

                           
                                <Row>
                                 
                                   <Col className="col-1 col-sm-1 " >{elemento.Usuario}</Col>
                                    <Col className="col-1 col-sm-1 " >{elemento.total}</Col>
                                    <Col className="col-2 col-sm-2 " >{elemento.cumplidas}</Col>
                                    <Col className="col-sm-2 d-none d-lg-block">{elemento.pendientes}</Col>
                                   
                                    {elemento.pendientes < 3 ?  <Col className="col-1 col-sm-1 d-none d-lg-block">   <img style={styles.img} src="img/rojaluz.webp" /> </Col> : 
                                    elemento.pendientes < 5 ?  <Col className="col-1 col-sm-1 d-none d-lg-block">   <img style={styles.img} src="img/amarillaluz.webp" /> </Col> : 
                                    <Col className="col-1 col-sm-1 d-none d-lg-block">   <img style={styles.img} src="img/verdeluz.png" /> </Col>  }
                                    
                                    <Col className="col-1 col-sm-1" style={{ width: '2%' }}> <Link to="/Agregarods" state={elemento}  ><FontAwesomeIcon icon={faFile} /> </Link></Col>
                                </Row>
                               



                        </Container>

                    )}







                    {ruta ? null : <p> NO HAY RUTAS CON ESA FECHA Y/O USUARIO </p>}

                   

                </div>

            </div>



        )

    }



}

export default Listaeditar;
