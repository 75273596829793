
import { useForm } from "react-hook-form"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useEffect, useState } from "react";
import Cerrarods from "./Paginas/Cerrarods";
import Verods from './Paginas/Verods';

import Insertarods from './Paginas/Insertarods';
import Hojaderutastate from "./Paginas/Hojaderutastate";
import Listaeditar from "./Paginas/Listaeditar";
import Listarmoviles from "./Paginas/Listarmoviles";
import Ingreso from "./Paginas/Ingreso";
import Header2 from "./Paginas/Plantillas/Header2"
import Footer from "./Paginas/Plantillas/Footer"
import Estado from "./Paginas/Plantillas/Estado"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Paginas/Plantillas/Login"

import './App.css';
import Logout from "./Paginas/Plantillas/Logout";
import Modificarods from "./Paginas/Modificarods";
import ImportExcel from "./Paginas/ImportExcel";
import hola from "../src/Paginas/Plantillas/hola";
import Abmrutas from "./Paginas/Abmrutas";
import Insertaruta from "./Paginas/Insertaruta";
import Agregarods from "./Paginas/Agregarods";
import Modificarruta from "./Paginas/Modificarruta";


function App() {

const [user,setUser] = useState(false)
const [nombre,setNombre] = useState('vacio')
const [fecha,setFecha] = useState('2022-12-01')

const handleState = (user) => {

  setUser(user)
console.log(user)
}

  return (

    <div className="App">



      <BrowserRouter>

     

      <Header2 nombre={nombre} fecha={fecha}/>

      
      { nombre == 'vacio'  ? 
      <Login  />: <Logout setNombre={setNombre} /> }

     


 



        <switch>
          <Routes>
            <Route path="/" element={<Ingreso setNombre={setNombre} setFecha={setFecha}/>} />
            
            <Route path="/Listaeditar" element={<Listaeditar user={nombre} fecha={fecha} setNombre={setNombre} setFecha={setFecha}/>} />
            <Route path="/Listarmoviles" element={<Listarmoviles user={nombre} fecha={fecha} setNombre={setNombre} setFecha={setFecha}/>} />
            <Route path="/Hojastate" element={<Hojaderutastate user={nombre} fecha={fecha}/>} />
            <Route path="/Cerrarods" element={<Cerrarods />} />
            <Route path="/Modificarods" element={<Modificarods />} />
            <Route path="/Insertarods" element={<Insertarods />} />
            <Route path="/Agregarods" element={<Agregarods />} />
            <Route path="/Verods" element={<Verods />} />
            <Route path="/Importar" element={<ImportExcel />} />
            <Route path="/prueba" element={<hola />} />
            <Route path="/abmrutas" element={<Abmrutas user={nombre} fecha={fecha} setNombre={setNombre} setFecha={setFecha}/>} />
            <Route path="/insertaruta" element={<Insertaruta />} />
            <Route path="/Modificarruta" element={<Modificarruta />} />


          </Routes>
        </switch>

      </BrowserRouter>

      <br />
      <br />
      <br />

      <Footer />


    </div>


  );
}

export default App;
