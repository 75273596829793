import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";

function Confirmacion( {isopen, close,  borrar} ) {
  const [show, setShow] = useState(isopen);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={isopen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Registro</Modal.Title>
        </Modal.Header>
        <Modal.Body>Realmente desea elimininar este Reclamo?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
           Cancelar
           </Button>
          <Button variant="primary" onClick={borrar}> 
          Eliminar
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Confirmacion;