import { useState, useEffect } from "react"

function useFetchid(url) {

    const [data, setData] = useState(null)
    const [loading, Setloading] = useState(true)
    const [elementos, Setelementos] = useState([]);
    const [ruta, SetRuta] = useState(false)


    useEffect(() => {

        Setloading(true)
        fetch(url)

            .then(res => res.json())
            .then(data => {

                Setloading(true)
               // setData(data)
                console.log(data)
                
                if (data.length > 0 ){

                    SetRuta(true)
                    
               } else {SetRuta(false)}
                
                setData(data)

                Setloading(false)


            })



    },

        []



    );

    return { data, loading, ruta };



}

export default useFetchid;
