
import { useForm } from "react-hook-form"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Row, Col, } from 'react-bootstrap'
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import Select from "react-select";
import useFetch from "../Componentes/useFetch";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import SignaturePad from 'react-signature-canvas'
import 'reactjs-popup/dist/index.css';
import '../Estilos/ordenes.css';
import '../Estilos/signature.css'
import Mensaje from "../Componentes/Mensaje";

const Cerrarods = (props) => {

  const sigCanvas = useRef({})
  const limpiar = () =>  {
    sigCanvas.current.clear()
    setImgurl(null)

  }  
  const guardar = () => setImgurl(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
  const [imgurl, setImgurl] = useState(null)

  const styles = {

    img: { height: "60px", width: "230px", paddingLeft: "120px" },
    header: {
      backgroundColor: "rgb(0, 0, 0)",
      width: "400px",

      color: "#008b8b",

    },
  }



  const { register, setValue, handleSubmit, formState: { errors } } = useForm();

  const location = useLocation();

  const elemento = location.state;

  const [isopen, setIsopen] = useState(false);
  const [msg, setMsg] = useState('');
  const [datos, setDatos] = useState([]);
  const [suma, setSuma] = useState({});
  const [guardado, setGuardado] = useState(false)
  const [resoluciones, setResolucion] = useState([])
  //  const { data, loading } = useFetch("http://localhost/BVC/index.php/Resolucion",)


  const Cerrar = () => {

    setIsopen(false)

  


}

  const handleselectchange = (event) => {
    console.log(event.value)

    setValue('cod_resol', event.value)

  }

  const newelem = {
    id: elemento.id,
    apellido: elemento.apellido,
    calle: elemento.calle,
    direccion: elemento.direccion,
  }

  let navigate = useNavigate();
  let goBack = () => {
    navigate(-1);
  };



  // const onSubmit = (data) => console.log(data)

  const onSubmit = data => {

    setMsg('');



    setSuma({

      ...data,
      ...newelem
    })



    fetch("https://dcocce.host/BVC/index.php/Rutas/Cerrar_json", {
      method: "POST",
      body: JSON.stringify(suma)
    })
      .then(res => res.json())
      .then(resp => {



        setGuardado(resp.guardado);
        setIsopen(resp.guardado);
        setMsg(resp.mensaje);

      })

  };

  useEffect(
    () => {


      fetch("https://dcocce.host/BVC/index.php/Resolucion/",)
        //fetch("http://localhost/BVC/index.php/Resolucion",)

        .then(res => res.json())
        .then(data => {

          setResolucion(data);
          console.log(resoluciones)


        })




    },

    []

  )

  return (

    <div className="d-flex flex-column" >

      <div><p>  </p></div>

      <div className="d-flex justify-content-center">
        <Card style={styles.header} className="text-center">
          <Card.Img variant="top" style={styles.img} src="img/bvcplay.png" />
          <Card.Body>
            <Card.Title className="text-center">Cerrar Orden</Card.Title>
            <Card.Text >



              <form onSubmit={handleSubmit(onSubmit)}>



                <table>

                  <tr>
                    <td>

                      <label>N° Internet</label>
                   

                      <input type="text" disabled
                        value={elemento.ni} />


                    </td>
                  </tr>
                  <tr>
                    <td>

                      <label>N° Cable</label>
                   
                      <input type="text" disabled
                        value={elemento.nc} />


                    </td>
                  </tr>
                  <tr>
                    <td>

                      <label>Apellido</label>
                  
                      <input type="text" disabled
                        value={elemento.apellido} />


                    </td>
                  </tr>
                  <tr>

                  </tr>
                  <tr>
                    <td>

                      <label>Direccion</label>
                   

                      <input type="text" disabled
                        value={elemento.direccion} />


                    </td>
                  </tr>
                  <tr>
                    <td>

                      <label>Falla</label>
                  
                      <textarea cols={30} rows={2} disabled
                        value={elemento.observaciones} />


                    </td>
                  </tr>
                  <tr>
                    <td>

                      <label>Fecha Cierre</label>
                 

                      <input type="date"
                        {...register("fecha_cump", { required: true })} />
                      {errors.fecha_cump && <p> La fecha es obligatoria</p>}

                    </td>
                  </tr>

                  <tr>
                    <td>

                      <label>Resolucion</label>
                 

                      {/* <input type="text" 
                      {...register("cod_resol",{required:true})} />
                      {errors.cod_resol && <p> Codigo de resolucion obligatorio</p>} */}

                      <p> <Select className="custom_select"

                        options={resoluciones.map(cod_resol => ({ value: cod_resol.id, label: cod_resol.descripcion }))}
                        onChange={handleselectchange}


                      /></p>


                    </td>
                  </tr>
                  <tr>
                    <td>

                      <label>Observaciones</label>
                   
                      <textarea cols={30} rows={5}
                        {...register("resolucion", { required: true })} />

                    </td>

                    <br />
                    <br />


                  </tr>

                  {msg ? <p>{msg}</p> : null}

                  <Mensaje isopen={isopen} msg={msg} aceptar={goBack} />


                  <tr>
                  <td>


</td>
<td>
                    {imgurl ? (

                      <img src={imgurl}
                        style={{
                          display: "block",
                          backgroundColor: "white",
                          height: "80px",
                          width: "200px",


                        }}
                      />

                    ) : null}
</td>
                  </tr>


                </table>

                <br />



                <Popup modal trigger={<Button variant="primary" type="submit">Firmar</Button>} closeOnDocumentClick={false}>
                  {close => (
                    <>
                      <SignaturePad
                        ref={sigCanvas}
                        canvasProps={{
                             width: 350,
                             height: 400,
                          className: "signatureCanvas"
                        }} />

                      <button onClick={close}>Cerrar</button>
                      <button onClick={limpiar}>Limpiar</button>
                      <button onClick={guardar}>Guardar</button>
                    </>
                  )}
                </Popup>

                <br />
                <br />

                <div className="d-grid gap-2">
                  <Button variant="primary" type="submit">Cerrar</Button>
                </div>
                <br />

                <div className="d-grid gap-2">
                  <Button variant="primary" onClick={goBack} >VOLVER</Button>
                </div>


              </form>

            </Card.Text>

          </Card.Body>
        </Card>

      </div>

    </div>



  );
}

export default Cerrarods;
