
import { useForm } from "react-hook-form"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Estilos/header.css"



function Ingreso({ setNombre, setFecha }) {


  const styles = {

    img: { height: "60px", width: "210px", paddingLeft: "120px" },

    header: {
      backgroundColor: "rgb(0, 0, 0)",
      width: "350px",

      color: "#008b8b",

    },

  }

  const { register, setValue, handleSubmit, formState: { errors } } = useForm();




  const [msg, setMsg] = useState('');

  const [login, setLogin] = useState(false);
  //const [fecha,setFecha] = useState("2022-12-08");
  const [user, setUser] = useState("");
  const [nivel, setNivel] = useState("");

  const elemento = {
    user: user,


  }

  // const onSubmit = (data) => console.log(data)

  const onSubmit = async data => {

    setMsg('');
    console.log(data)

    //"https://bvcdespacho.000webhostapp.com/BVC/index.php/Usuarios/registrado_json"

    fetch("https://dcocce.host/BVC/index.php/Usuarios/registrado_json", {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(resp => {

        console.log(resp);



        setMsg(resp.mensaje);

        if (resp.registrado) {
          //setUser(true);

          setNombre(resp.usuario)
          setUser(resp.usuario)
          setLogin(resp.registrado)
          setFecha(resp.fecha)
          setNivel(resp.nivel)
        }
      }) 
      
     .catch((error) => setMsg(error));

      
    console.log(data);
   

  };

  return (

    <div className="d-flex flex-column" >

      <div>
        <p> </p>

      </div>


      <div className="d-flex justify-content-center" >
        <Card style={styles.header}  >
          <Card.Img variant="top" style={styles.img} src="img/bvcplay.png" />
          <Card.Body>
            <Card.Title className="text-center">Ingreso al Sistema</Card.Title>
            <Card.Text className="d-flex justify-content-center">
              <br />
              <form onSubmit={handleSubmit(onSubmit)}>

                <table>

                  <tr>
                    <td>
                      <label className="text-center" >Usuario</label>

                    </td>
                    <td>
                      <div className="text-center">

                        <input type="text" {...register("nombre")} />

                      </div>
                    </td>
                  </tr>

                  <br />
                  <tr>
                    <td>
                      <label className="text-center" >Clave</label>
                    </td>
                    <td>
                      <div className="text-center">

                        <input type="text" {...register("pass")} />

                      </div>

                    </td>
                  </tr>

                  <br />

                  <tr>
                    <td>

                      <label>Fecha</label>
                    </td>
                    <td>

                      <div className="text-center">

                        <input type="date"
                          {...register("fecha_cump", { required: true })} />
                        {errors.fecha_cump && <p> La fecha es obligatoria</p>}
                      </div>
                    </td>
                  </tr>

                  <br />

                    <td>


                    </td>

                  
                     
                   

                    </table>

                    {msg ? <p>{msg}</p> : null}
                  
                    
                      <div className="d-grid gap-2">
                        {nivel == 1 ?
                          <Button variant="ligth" size="lg" disabled={!login}  ><Link to="/Listaeditar" > Despacho</Link></Button> : null}
                        <Button variant="primary" size="lg" type="submit">Validar</Button>
                      </div>

                      <p>  </p>

                      <div className="d-grid gap-2">
                        {nivel == 2 ? <Button variant="ligth" size="lg" disabled={!login}  ><Link to="/Hojastate" > Movil</Link></Button> : null}
                      </div>

                  

                 

               
              </form>

            </Card.Text>

          </Card.Body>
        </Card>
      </div>


    </div>



  );
}

export default Ingreso;
